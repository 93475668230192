import { Link } from 'react-router-dom';
import ResponsiveMenu from './ResponsiveMenu';
import React, { useState } from 'react';
import BtLogo from '../library/Logo/BtLogo';

function Header(props: any): any {
  const [active, setActive] = useState<boolean>(false);

  const showMenu = () => (): void => {
    setActive(!active);
  };
  return (
    <div>
      <div className="bg-bt-white w-full md:grid lg:grid lg:grid-cols-8 lg:gap-4 md:py-4 lg:py-4 py-1">
        <div className="lg:col-start-0 col-span-8 pr-4 flex flex-row justify-between items-center">
          <Link className="lg:max-h-12 " to="/" title="Home" rel="home">
            <BtLogo />
          </Link>
          <div className="text-white inline-flex p-3 hover:bg-gray-900 rounded md:hidden lg:hidden ml-auto hover:text-white outline-none nav-toggler">
            {!active ? (
              <div onClick={showMenu}>
                <div className="w-6 h-[3px] bg-black m-[7px] duration-500"></div>
                <div className="w-6 h-[3px] bg-black m-[7px] duration-500"></div>
                <div className="w-6 h-[3px] bg-black m-[7px] duration-500"></div>
              </div>
            ) : (
              <div onClick={showMenu}>
                <div
                  className="w-6 h-[3px] bg-black m-[7px] duration-500"
                  style={{
                    transform: 'translate(0, 9px) rotate(-45deg)'
                  }}
                ></div>
                <div className=" opacity-0 w-6 h-[3px] bg-black m-[7px] duration-500"></div>
                <div
                  className=" w-6 h-[3px] bg-black m-[7px] duration-500"
                  style={{
                    transform: 'translate(0, -11px) rotate(45deg)'
                  }}
                ></div>
              </div>
            )}
          </div>

          <div className="hidden md:flex flex-row lg:w-1/5 md:w-[87%] md:text-sm justify-between text-black font-bold md:pl-3.5">
            <Link to="/">About</Link>
            <Link to="/register">Register</Link>
            <Link to="/login">Sign-in</Link>
          </div>
        </div>
      </div>
      <ResponsiveMenu showMenu={showMenu} active={active} />
    </div>
  );
}

export default Header;
