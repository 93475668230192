import React from 'react';

const Search = (): any => (
  <form action="/" method="get">
    <div className="float-right">
      <label htmlFor="header-search">
        <span className="visually-hidden text-[#7E7E7E]">Search: </span>
      </label>
      <input
        type="text"
        className="border-black p-[4.8px 4.8px] rounded-lg"
        style={{ border: '1px solid gray' }}
        id="header-search"
        name="s"
      />
    </div>
  </form>
);

export default Search;
