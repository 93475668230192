import React from 'react';
import { Link } from 'react-router-dom';

const LeftPanel: React.FC<any> = (props: any) => {
  return (
    <div className="fixed left-0 z-[5] w-[300px] h-[100rem] top-[6rem] pt-0 pb-[100px] bg-[#f8f8f8] box-border">
      <nav className="mb-10 mt-0">
        <ul className="pt-[30px]">
          <li className="relative px-[16px]">
            <Link
              className="flex items-center text-black py-[15px] px-[20px] w-full relative no-underline hover:text-iris"
              to={''}
            >
              <span> Manage Licenses/Agreements </span>
            </Link>
          </li>
          <li className="relative px-[16px]">
            <Link
              className="flex items-center text-black py-[15px] px-[20px] w-full relative no-underline hover:text-iris"
              to={''}
            >
              <span> Documentation </span>
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default LeftPanel;
