import React, { Fragment, useState } from 'react';
import CardNoBorder from '../CardNoBorder';

function CardAdmin(props: any): any {
  const [cards, setCards] = useState([
    {
      key: 'first',
      title: 'Modules',
      content: '2 modules available, click for more information.',
      bgColor: 'black'
    },
    {
      key: 'second',
      title: 'Users',
      content: '1 user still has not validated their account > 10 days.',
      bgColor: 'white'
    },
    {
      key: 'third',
      title: 'Agreements',
      content: '1 licence file is awaiting your review.',
      bgColor: 'black'
    }
  ]);

  const removeCard = (index: number): any => {
    const newCards = cards.filter((_, i) => i !== index);
    setCards(newCards);
  };

  return (
    <Fragment>
      <div
        className="flex grid grid-cols-4 gap-4   bg-[#f8f8f8]"
        style={{ paddingInline: '311px 11px' }}
      >
        {cards.map((card, index) => (
          <CardNoBorder
            key={index}
            title={card.title}
            content={card.content}
            backGroundColor={card.bgColor}
            closeHandler={() => removeCard(index)}
          />
        ))}
      </div>
    </Fragment>
  );
}

export default CardAdmin;
