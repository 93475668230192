import React from 'react';
interface IInputField {
  id: string;
  type: string;
  className: string;
  value?: string;
  reference?: any;
  required?: boolean;
  style?: React.CSSProperties;
  disabled?: boolean;
  onChange?: (e: React.FormEvent) => void;
  minimum?: any;
  onClick?: (e: React.FormEvent) => void;
}

const InputField: React.FC<IInputField> = ({
  id,
  type,
  className,
  value,
  reference,
  required,
  style,
  disabled,
  onChange,
  onClick,
  minimum = () => {}
}) => {
  return (
    <input
      id={id}
      type={type}
      className={className}
      style={style}
      required={required}
      disabled={disabled}
      ref={reference}
      value={value}
      onChange={onChange}
      min={minimum()}
      onClick={onClick}
    />
  );
};
export default InputField;
