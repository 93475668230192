import React from 'react';
import CloseButton from '../library/EditAndDelete/closeButton';
import Close from '../assets/img/close.png';
import CloseWhite from '../assets/img/close-white.png';

interface CardProps {
  title: string | null;
  content: string | null;
  backGroundColor: string | 'white';
  closeHandler: (e: React.FormEvent) => void;
}

const CardNoBorder: React.FC<CardProps> = ({ title, content, backGroundColor, closeHandler }) => {
  let cardBackgroundColor, textColor, closeIcon, iconColor;
  switch (backGroundColor) {
    case 'black':
      cardBackgroundColor = 'bg-black';
      textColor = 'text-white';
      closeIcon = CloseWhite;
      iconColor = 'white';
      break;
    case 'white':
    default:
      cardBackgroundColor = 'bg-white';
      textColor = 'text-black';
      closeIcon = Close;
      iconColor = 'black';
  }

  return (
    <div className={`block p-6 rounded-lg shadow-lg ${cardBackgroundColor} max-w-sm mt-[100px]`}>
      <div className="flex justify-between">
        <h5 className={`${textColor} text-xl leading-tight font-medium mb-2`}>{title}</h5>
        <CloseButton
          className=""
          closeIcon={closeIcon}
          iconColor={iconColor}
          clickHandler={closeHandler}
        />
      </div>
      <div>
        <p className={`${textColor} text-base mb-4`}>{content}</p>
      </div>
    </div>
  );
};

export default CardNoBorder;
