import React from 'react';
const Show = (): any => (
  <form action="/" method="get">
    <div className="float-left">
      <label htmlFor="header-search">
        <span className="visually-hidden text-[#7E7E7E]">Show: </span>
      </label>
      <select
        className="border-black p-[4.8px 8px] rounded-lg"
        style={{ border: '1px solid gray' }}
        id="header-show"
        name="s"
      >
        <option value="" label=""></option>
        <option value="1" label="1">
          1
        </option>
        <option value="2" label="2">
          2
        </option>
        <option value="3" label="3">
          3
        </option>
        <option value="4" label="4">
          4
        </option>
        <option value="5" label="5">
          5
        </option>
        <option value="6" label="6">
          6
        </option>
        <option value="7" label="7">
          7
        </option>
        <option value="8" label="8">
          8
        </option>
        <option value="9" label="9">
          9
        </option>
        <option value="10" label="10">
          10
        </option>
      </select>
      <label htmlFor="header-search">
        <span className="visually-hidden text-[#7E7E7E]"> entries</span>
      </label>
    </div>
  </form>
);

export default Show;
