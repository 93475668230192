import React from 'react';
interface ILabel {
  className: string;
  LabelText: string;
  disabled?: boolean;
  onlick?: (e: React.FormEvent) => void;
  htmlFor?: string;
}

const Label: React.FC<ILabel> = ({ className, LabelText, onlick, htmlFor }) => {
  return (
    <label className={className} onClick={onlick} htmlFor={htmlFor}>
      {LabelText}
    </label>
  );
};
export default Label;
