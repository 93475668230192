import React from 'react';
interface IImageTag {
  src: string;
  className?: string;
  alt: string;
  width?: string;
  height?: string;
  maxWidth?: string;
  maxHeight?: string;
  style?: React.CSSProperties;
  onChange?: (e: React.FormEvent) => void;
  loading?: string;
}

const ImageTag: React.FC<IImageTag> = ({
  src,
  className,
  alt,
  width,
  height,
  style,
  onChange,
  maxWidth,
  maxHeight
}) => {
  return (
    <img
      src={src}
      className={className}
      style={style}
      alt={alt}
      onChange={onChange}
      width={width}
      height={height}
      max-width={maxWidth}
      max-height={maxHeight}
    />
  );
};
export default ImageTag;
