import React from 'react';
interface IButton {
  className: string;
  closeIcon: string;
  iconColor: string;
  disabled?: boolean;
  clickHandler: (e: React.FormEvent) => void;
}

const closeButton: React.FC<IButton> = ({ className, closeIcon, iconColor, clickHandler }) => {
  let width, height;
  if (iconColor === 'white') {
    width = height = 16;
  } else {
    width = height = 22;
  }
  return (
    <button className={className} onClick={clickHandler}>
      <img src={closeIcon} alt="close" width={width} height={height} />
    </button>
  );
};
export default closeButton;
