import React from 'react';
import ImageTag from '../library/ImageTag/ImageTag';

interface CardProps {
  title: string | null;
  content: string | null;
  image: any;
}

const Card: React.FC<CardProps> = ({ image, title, content }) => {
  return (
    <div className="card mr-4 my-7 px-7 py-7 items-center justify-center flex-1 flex-col border border-light-gray rounded-none bg-white">
      <ImageTag
        alt="partner with us"
        data-entity-type="file"
        data-entity-uuid="fe297017-1afb-4270-9664-ff036f4b1840"
        src={image}
        width="74"
        height="74"
        className="mb-4 ml-[80px]"
      />

      <p className="font-medium text-black text-lg my-4">{title}</p>
      <p className="font-normal text-black text-sm ">{content}</p>
    </div>
  );
};

export default Card;
