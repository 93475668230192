import React from 'react';
import ImageTag from '../ImageTag/ImageTag';
import logo from '../Logo/logo.png';

const BtLogo: React.FC<any> = () => {
  return (
    <ImageTag
      className="  pl-5 md:pl-3.5 lg:pd-0 "
      src={logo}
      alt="Home"
      width="150"
      maxHeight="100"
    />
  );
};
export default BtLogo;
