import React from 'react';

function Footer(props: any): any {
  return (
    <div className="relative bg-black md:grid  lg:grid lg:grid-cols-8 gap-4 py-4 md:py-8 lg:py-4 z-[9999]">
      {/* <div className="col-start-4 md:col-start-2 lg:col-start-3 col-span-6  md:flex md:flex-row  lg:flex lg:flex-row justify-center ml-0 lg:ml-24 md:ml-0"></div> */}

      <div className="relative h-16 w-32">
        <div className="absolute text-white bottom-0 left-0 h-32 w-32 pt-28 pl-2 ...">
          © BT Group plc
        </div>
      </div>
    </div>
  );
}

export default Footer;
