import React, { Fragment } from 'react';
import Card from './Card';
import microprocessor from '../assets/img/microprocessor.png';
import router from '../assets/img/router.png';
import api_gear from '../assets/img/api_gear.png';
import cubes from '../assets/img/cubes.png';

const AreasOfExperties: React.FC<any> = (props: any) => {
  return (
    <Fragment>
      <Card
        title="Silicon providers"
        content="At the ore of all CPE hardwar are the chipsets e.g. SoC, Wi-fi, Dect,
        Iot. The strategy for Indigo is to support a diversity of chipsets to
        allow us maximum choice when building new products."
        image={microprocessor}
      />
      <Card
        title="Device manufacturers"
        content="We work with ODM partners to build these for us. The ODMs in turn
          work with the silicon manufacturers. Together they proide the
          platform of hardware and low-level software that Indigo runs on."
        image={router}
      />
      <Card
        title="Specialist software providers"
        content="Whe appropriate Indigo may make use of specialist software modules
          to provide the features required. Examples include software DSP for
          voice, MPTCP, speed test."
        image={api_gear}
      />
      <Card
        title="3rd party app suppliers"
        content="Indigo includes a container framework to allow sandboxed applications
          to excute on the CPE. These can interact with the device via a
          high-level API based on USP."
        image={cubes}
      />
    </Fragment>
  );
};

export default AreasOfExperties;
