import React from 'react';
import Check from '../../assets/img/check.png';

const ProgressBar: React.FC<any> = () => {
  return (
    <div
      className="items-center grid w-[76%] ml-[60px]"
      style={{ gridTemplateColumns: 'repeat(3,20px 1fr 1fr) 20px' }}
    >
      <div
        className=" bg-no-repeat text-white bg-[#1196AB] relative w-5 h-5 rounded-[50%]"
        style={{
          backgroundPositionX: 'center',
          backgroundPositionY: 'center',
          backgroundSize: '50%',
          backgroundImage: `url(${Check})`
        }}
      ></div>
      <div
        className="h-2 col-span-2 w-[102%] -ml-1"
        style={{
          background: 'linear-gradient(to right,#1196AB 100.0%,#D5D9D9 100.0% 100%)'
        }}
      ></div>
      <div
        className="bg-no-repeat text-white bg-[#1196AB] relative w-5 h-5 rounded-[50%]"
        style={{
          backgroundPositionX: 'center',
          backgroundPositionY: 'center',
          backgroundSize: '50%',
          backgroundImage: `url(${Check})`
        }}
      ></div>
      <div
        className="h-2 col-span-2 w-[102%] -ml-1"
        style={{
          background: 'linear-gradient(to right,#1196AB 100.0%,#D5D9D9 100.0% 100%)'
        }}
      ></div>
      <div
        className="bg-[ bg-no-repeat text-white bg-[#1196AB] relative w-5 h-5 rounded-[50%]"
        style={{
          backgroundPositionX: 'center',
          backgroundPositionY: 'center',
          backgroundSize: '50%',
          backgroundImage: `url(${Check})`
        }}
      ></div>
      <div
        className="h-2 col-span-2 w-[102%] -ml-1"
        style={{
          background: 'linear-gradient(to right,black 100.0%,#D5D9D9 100.0% 100%)'
        }}
      ></div>
      <div
        className=" bg-no-repeat text-white bg-black relative w-5 h-5 rounded-[50%]"
        style={{
          backgroundPositionX: 'center',
          backgroundPositionY: 'center',
          backgroundSize: '50%',
          backgroundImage: `url(${Check})`
        }}
      ></div>
      <div className="text-black col-end-2 text-left text-[13px] mt-[3px] z-50 -ml-10 w-[106px]">
        Application Submitted
      </div>
      <div
        className="h-2 col-span-2 w-[102%] -ml-1"
        style={{
          background: 'linear-gradient(to right,white 0.0%,white 0.0% 70%)'
        }}
      ></div>
      <div className="text-black text-center text-[13px] mt-[3px] z-50 w-[114px] -ml-[50px]">
        Partner Portal Tx & Cs Accepted
      </div>
      <div
        className="h-2 col-span-2 w-[102%] -ml-1"
        style={{
          background: 'linear-gradient(to right,white 0.0%,white 0.0% 70%)'
        }}
      ></div>
      <div className="text-black text-center text-[13px] mt-[3px] z-50 w-[100px] -ml-[38px]">
        Application Reviewed
      </div>
      <div
        className="h-2 col-span-2 w-[102%] -ml-1"
        style={{
          background: 'linear-gradient(to right,white 0.0%,white 0.0% 100%)'
        }}
      ></div>
      <div className="text-black text-right text-[13px] mt-[3px] z-50 -ml-3">
        Application Approved
      </div>
    </div>
  );
};
export default ProgressBar;
